<template>
	<a-spin :spinning="!loading">
		<case-details @getCaseDetailsData="getCaseDetailsData" :caseStates="'view_detail'">
			<template slot="titleBtn">
				<span class="row-start-center f-1">
					<!-- <a-button type="primary" :disabled="data.loanInfo?.disburseType !== 'choice'" @click="toDisburseNow"> Disburse Now </a-button>
					<a-button type="primary" style="margin-left: 10px" @click="toRecordDisbursementEntries"> Record Disbursement Entries </a-button> -->
					<div class="f-1" />
					<pass-disburse-record-modal
						:loanId="detailData.id"
						:loanType="detailData.loanType"
						:processNodeId="detailData.processStatus?.processNode?.id"
						listPagePath="pendinglist"
					>
						<a-button
							slot="reference"
							type="primary"
							:disabled="!$hasPermission('ROLE_PENGING_LOAN_CHECKER')"
							:class="{ 'btn-pass': $hasPermission('ROLE_PENGING_LOAN_CHECKER') }"
						>
							{{ $t('operate.comfireAmount') }}
						</a-button>
					</pass-disburse-record-modal>
					<a-button
						type="primary"
						:disabled="!($hasPermission('ROLE_PENGING_LOAN_MAKER') || $hasPermission('ROLE_PENGING_LOAN_CHECKER'))"
						:class="{ 'btn-reject': $hasPermission('ROLE_PENGING_LOAN_MAKER') && $hasPermission('ROLE_PENGING_LOAN_CHECKER') }"
						style="margin-left: 10px"
						@click="ShowFailModal"
					>
						{{ $t('operate.auditFail') }}
					</a-button>
				</span>
			</template>
		</case-details>
		<!-- 审核不通过 -->
		<a-modal v-drag-modal :title="$t('table.applyInfo.titleMap.title2')" width="800px" v-model="ModalFail.show" @ok="AuditFail">
			<a-form-model :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
				<a-form-model-item :label="$t('table.loanReview.Seeting.modal.reason')">
					<a-radio-group v-model="ModalFail.checkFailType">
						<a-radio :value="item" v-for="item in [1, 0]" :key="item">
							{{ $t(`table.loanReview.Seeting.modal.reasonMap.${item}`) }}
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item :label="$t('table.loanReview.Seeting.modal.remark')">
					<a-input style="height: 100px" v-model="ModalFail.checkText" type="textarea" :maxLength="300" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</a-spin>
</template>
<script>
import CaseDetails from '@/views/businessComponents/CaseDetails'
import moment from 'moment'
import { apiProcessCheck } from '@/api/check'
import PassDisburseRecordModal from '@/components/disburse/pass-disburse-record-modal.vue'

export default {
	components: {
		CaseDetails,
		PassDisburseRecordModal
	},
	data() {
		return {
			id: this.$route.query.id,
			loading: true,
			processStatus: '',
			ModalFail: {
				show: false,
				checkText: '',
				checkFailType: 0
			},
			totalDisburseAmount: 0,
			detailData: {}
		}
	},
	methods: {
		moment: moment,
		closeTab(targetKey) {
			this.$store.commit('tabs/delTab', targetKey)
			this.$store.commit('tabs/setActiveTab', '/home/loandata/pendinglist')
		},
		getCaseDetailsData(val) {
			this.totalDisburseAmount = val.loanInfo.amountPayable + val.loanInfo.hasInsurance === 1 ? val.loanInfo.insuranceAmount : 0
			this.processStatus = val.processStatus
			this.detailData = val
		},
		// 放款弹窗显示
		ShowFailModal() {
			this.ModalFail = {
				show: true,
				checkText: '',
				checkFailType: 0
			}
		},
		// 审核不通过
		AuditFail() {
			if (this.ModalFail.checkText) {
				if (this.ModalFail.checkText == '' && this.Modal.checkFailType == 0) {
					this.$message.error('remark is required')
					return false
				}
				let param = {
					loanId: this.id,
					pass: 0,
					checkType: 'check',
					checkFailType: this.ModalFail.checkFailType,
					checkText: this.ModalFail.checkText,
					nodeId: this.processStatus.processNode.id
				}
				apiProcessCheck(param).then((res) => {
					this.$message.success('success')
					this.closeTab(this.$route.fullPath)
					this.$router.push('pendinglist')
				})
			} else {
				this.$message.error('remark is required')
			}
		}
		// toDisburseNow() {
		// 	this.$router.push({
		// 		path: 'disburseNow',
		// 		query: {
		// 			loanId: this.detailData.id,
		// 			loanType: this.detailData.loanType
		// 		}
		// 	})
		// },
		// toRecordDisbursementEntries() {
		// 	this.$router.push({
		// 		path: 'recordDisbursementEntries',
		// 		query: {
		// 			loanId: this.detailData.id,
		// 			loanType: this.detailData.loanType,
		// 			disburseType: this.detailData.loanInfo.disburseType || 'dtb'
		// 		}
		// 	})
		// }
	}
}
</script>
<style scoped lang="less">
.ant-form-item {
	margin-bottom: 0;
}
.ant-input-number {
	width: 100%;
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.btn-pass {
	background-color: #67c23a;
	border-color: #67c23a;
}
.btn-reject {
	background-color: #e6a23c;
	border-color: #e6a23c;
}
</style>
