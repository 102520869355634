<template>
	<span>
		<span @click="toggle">
			<slot name="reference" />
		</span>

		<a-modal v-if="isShow" :visible="true" title="Loan Batch Details" width="1100px" @cancel="toggle">
			<a-table :columns="columns" :data-source="tableData" :row-key="(row, index) => index" bordered style="margin-top: 10px" />

			<template slot="footer">
				<a-button @click="toggle"> Cancel </a-button>
				<a-button :disabled="!!tableData.find((i) => i.status === '2')" :loading="isLoading" type="primary" @click="pass"> Pass </a-button>
			</template>
		</a-modal>
	</span>
</template>

<script>
import { getDisburseCheckRecord } from '@/api/loan'
import { apiProcessCheck } from '@/api/check'
import { getBankInfo } from '@/utils'

export default {
	props: {
		bankList: {
			type: Array
		},
		loanId: {
			type: String
		},
		loanType: {
			type: String
		},
		processNodeId: {
			type: String
		},
		listPagePath: {
			type: String
		}
	},
	data() {
		return {
			isShow: false,
			tableData: [],
			isLoading: false
		}
	},
	computed: {
		columns() {
			return [
				{
					title: 'Bank',
					align: 'center',
					dataIndex: 'disburseType'
				},
				{
					title: this.$t('table.Payee-Bank-Institution'),
					align: 'center',
					customRender: (row) => getBankInfo(row.payeeBankCode).name
				},
				{
					title: this.$t('table.Payee-Name'),
					dataIndex: 'disburseName',
					align: 'center'
				},
				{
					title: this.$t('table.Payee-Account-Number'),
					dataIndex: 'disburseAccount',
					align: 'center'
				},
				{
					title: this.$t('table.Disbursement-Amount'),
					dataIndex: 'disburseAmount',
					align: 'center'
				},
				{
					title: this.$t('table.Payment-Time'),
					dataIndex: 'paidTime',
					align: 'center'
				},
				{
					title: this.$t('table.Status'),
					align: 'center',
					customRender: (row) => row.status !== undefined && this.$t('enumerate.disburse-status' + `.${row.status}`)
				}
			]
		}
	},
	methods: {
		toggle() {
			this.isShow = !this.isShow
			if (this.isShow) {
				this.fetchTableData()
			}
		},
		async fetchTableData() {
			const params = {
				loanId: this.loanId,
				loanType: this.loanType
			}
			this.tableData = await getDisburseCheckRecord(params)
		},
		async pass() {
			try {
				this.isLoading = true
				const params = {
					loanId: this.loanId,
					loanType: this.loanType,
					nodeId: this.processNodeId,
					pass: 1,
					checkType: 'check',
					checkText: 'Passed'
				}
				const res = await apiProcessCheck(params)
				this.$message.success('Success')
				this.toggle()
				this.closeTab(this.$route.fullPath)
				this.$router.push(this.listPagePath).then(() => {
					this.$store.commit('tabs/setActiveTab', this.$route.fullPath)
				})
			} finally {
				this.isLoading = false
			}
		},
		closeTab(targetKey) {
			this.$store.commit('tabs/delTab', targetKey)
		}
	}
}
</script>
<style lang="less" scope></style>
